<template>
  <footer id="main-footer" class="d-flex flex-column justify-content-center align-items-center">
    <slither class="slither py-md-4">
      <div class="d-none d-md-flex">
        Our customers say
        <trust-pilot id="trustpilot-footer-widget" template="5419b732fbfb950b10de65e5" theme="light"></trust-pilot>
      </div>
    </slither>
    <div class="content width-limiter">
      <div class="container-fluid mt-4">
        <div class="row">
          <div class="col-12 col-override order-md-1">
            <form method="POST" action=https://mstore.activehosted.com/proc.php id="_form_12_" ref="newsletterFooterForm">
                <input type="hidden" name="u" value="12" />
                <input type="hidden" name="f" value="12" />
                <input type="hidden" name="s" />
                <input type="hidden" name="c" value="0" />
                <input type="hidden" name="m" value="0" />
                <input type="hidden" name="act" value="sub" />
                <input type="hidden" name="v" value="2" />
            <h6 class="newsletter-title">Subscribe to Our Newsletter</h6>
            <p class="text-small">Subscribe to our newsletter today for exclusive offers and product updates.</p>
            <div class="row mb-3">
              
                <div class="col" style="padding-right: 0px">
                  <input type="email" name="email" v-model="form.email" placeholder="Email" style="width: 100%; height: 40px; border-radius: 0px" id="footer-newsletter" ref="footer-newsletter-input" />
                </div>
                <div class="col-auto" style="padding-left: 0px">
                  <button-primary @click.prevent="submitForm" style="
                    width: 100%;
                    height: 40px;
                    border-radius: 0px 3px 3px 0px;
                    padding-top: 7px;
                  ">SUBSCRIBE</button-primary>
                </div>
              
            </div>
            </form>
          </div>
          <div class="col-12 col-override">
            <h6 @click="toggleMenu($event)">
              Contact Us
              <icon class="d-md-none" style="pointer-events: none;" type="plus" width="18px" height="18px" />
            </h6>
            <div class="d-flex flex-column collapse">
              <click-to-call :dark="true" :phone="'+441618049092'" :label="'+44 (0)161 804 9092'" />
              <click-to-email :email="'store@mstore.co.uk'" :label="'store@mstore.co.uk'" :dark="true" />
              <router-link :to="{ name: 'contact-us' }">Get in Touch</router-link>
            </div>
            <div class="row icons d-none d-md-flex">
              <div class="col-1 icon">
                <a href="https://www.instagram.com/mstoreppe" aria-label="instagram mstoreppe" target="_blank">
                  <icon type="instagram" width="30px" height="25px" />
                </a>
              </div>
              <div class="col-1 icon">
                <a href="https://twitter.com/mstoreppe" aria-label="twitter mstoreppe" target="_blank">
                  <icon type="twitter" width="30px" height="25px" />
                </a>
              </div>
              <div class="col-1 icon">
                <a href="https://www.facebook.com/mstoreppe" aria-label="facebook mstoreppe" target="_blank">
                  <icon type="facebook" width="30px" height="25px" />
                </a>
              </div>
              <div class="col-1 icon">
                <a href="https://www.tiktok.com/@mstore_ppe" aria-label=".tiktok mstoreppe" target="_blank">
                  <icon type="tiktok" width="30px" height="25px" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-12 col-override">
            <h6 @click="toggleMenu($event)">
              Customer Service
              <icon class="d-md-none" style="pointer-events: none;" type="plus" width="18px" height="18px" />
            </h6>
            <div class="d-flex flex-column collapse">
              <router-link :to="{ name: 'safe-secure-payments' }">Safe & Secure Payments</router-link>
              <router-link :to="{ name: 'delivery-and-returns' }">Delivery and Returns</router-link>
              <router-link :to="{ name: 'account-orders' }">My Orders</router-link>
              <router-link to="faqs">FAQs</router-link>
              <!-- <router-link :to="{ name: 'returns-policy' }">Returns Policy</router-link> -->
            </div>
          </div>
          <div class="col-12 col-override">
            <h6 @click="toggleMenu($event)">
              Information
              <icon class="d-md-none" style="pointer-events: none;" type="plus" width="18px" height="18px" />
            </h6>
            <div class="d-flex flex-column collapse">
              <router-link to="/partnership">Partnership</router-link>
              <router-link :to="{ name: 'blog' }">Latest News</router-link>
              <router-link :to="{ name: 'sustainability' }">Sustainability</router-link>
              <router-link to="/glove-cleaning-and-size-guide">Glove Size and Cleaning Guide</router-link>
              <router-link :to="{ name: 'about-us' }">About Mstore</router-link>
            </div>
          </div>
        </div>
        <div class="row mobile-icons d-md-none">
          <div class="col-auto icon">
            <a href="https://www.instagram.com/mstoreppe">
              <icon type="instagram" width="30px" height="30px" />
            </a>
          </div>
          <div class="col-auto icon">
            <a href="https://twitter.com/mstore_retail">
              <icon type="twitter" width="30px" height="30px" />
            </a>
          </div>
          <div class="col-auto icon">
            <a href="https://www.facebook.com/mstoreppe">
              <icon type="facebook" width="30px" height="30px" />
            </a>
          </div>
          <div class="col-auto icon">
            <a href="https://www.tiktok.com/@mstore_ppe">
              <icon type="tiktok" width="30px" height="30px" />
            </a>
          </div>
        </div>
        <div class="copyright row">
          <div class="col-12 col-md-auto">Copyright © MStore Limited {{year}}. All rights reserved.</div>
          <div class="col-12 col-md-auto links">
            <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>

            <router-link :to="{ name: 'terms-and-conditions' }">Terms and Conditions</router-link>

            <!-- <router-link :to="{ name: 'accessibility' }">Accessibility</router-link> -->
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <small>
              <sup>*1</sup> 10% off your first order when you subscribe to our
              newsletter.
            </small>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import buttonPrimary from "../ui/buttons/buttonPrimary.vue";
import slither from "./slither";
import trustPilot from "../trustPilot";
import ClickToCall from '../ui/clickToCall.vue';
import ClickToEmail from '../ui/clickToEmail.vue';

export default {
  components: {
    slither,
    buttonPrimary,
    trustPilot,
    ClickToCall,
    ClickToEmail,
  },
  data() {
    return {
      form: {
        email: '',
      },
    }
  },
  methods: {
    toggleMenu(event) {
      event.target.nextElementSibling.classList.toggle("showing");
    },
    submitForm() {
      // Validate the email address
      if (!this.form.email) {
        this.$refs['footer-newsletter-input'].focus();
        return;
      }
      this.$store.dispatch("auth/optUserNewsletter", {email: this.form.email, source: "footer"}).then(() => {
        this.form.email = null; 
        this.$router.push({
          name: "thank-you",
          params: {
            message: {
              header: "You have subscribed to our newsletter!",
              body: "",
            },
            ctas: [
              {
                message: "CONTINUE SHOPPING",
                link: "products",
                type: "primary",
              },
            ],
          },
        });
        // Avoid form submission, we will create the contact on AC or Mailchimp from the backend
        // this.$refs.newsletterFooterForm.submit();
      }, () => {
          this.$refs['footer-newsletter-input'].focus();
          // push notification error
          this.$store.commit("app/addNotification", {
            type: "bad",
            title: "Invalid email address",
            message: "Please check the email is correct",
            icon: "circleExclamation",
          }); 
        });
    },
  },
  computed:{
    year(){
      return (new Date()).getFullYear();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "src/scss/breakpoints.scss";

.width-limiter {
  width: calc(100% - 20px);
  max-width: 1600px;
}

// Override the default col-md-auto behaviour
.col-override {
  @include media-breakpoint-up(md) {
    flex: 1 0 0%;
  }
}

#main-footer {
  background: #202122;
  color: white;
  .slither {
    color: black;
    max-height: 8px;
    width: 100%;
    #trustpilot-footer-widget {
      position: relative;
      top: 2px;
    }
  }
  .content {
    a {
      color: white;
      text-decoration: none;
    }
    .newsletter-title {
      font-size: 1.4em;
      @include media-breakpoint-up(md) {
        font-size: 1em;
      }
    }
    h6:not(.newsletter-title) {
      font-size: 0.8em;
      cursor: pointer;
      background: white;
      color: black;
      text-transform: uppercase;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 20px;
      margin-bottom: 18px;
      i {
        font-size: 1.3em;
        pointer-events: none;
      }
      @include media-breakpoint-up(md) {
        font-size: 1em;
        cursor: auto;
        background: #202122;
        text-transform: none;
        color: white;
        height: 0px;
        padding: 10px 0px 20px;
        margin-bottom: 0px;
      }
    }
  }
  input {
    background: #202122;
    color: white;
    border: 2px solid #feb103;
    padding: 1px 23px;
    width: 100%;
    &::placeholder {
      color: #fff;
      opacity: 1;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  .copyright {
    padding: 30px 0px;
    font-size: 0.8em;
    @include media-breakpoint-up(md) {
      font-size: 0.8em;
    }
    .links {
      a {
        padding: 0px 20px 0px 0px;
        font-size: 0.8em;
        border-left: 1px solid white;
        padding-left: 10px;
        &:first-child {
          border-left: none;
          padding-left: 0px;
        }
        @include media-breakpoint-up(md) {
          padding: 0px 10px;
        }
      }
    }
  }
}

.icons {
  font-size: 1.5em;
  margin-top: 5px;
  .icon {
    margin: 0px 13px 0px 0px;
  }
}
.mobile-icons {
  font-size: 1.7em;
  .icon {
    padding-right: 10px;
  }
}

/* Collapsible menus */
.collapse {
  max-height: 0px;
  overflow: hidden;
  padding: 0px 30px 0px;
  transition: all 350ms ease-in-out;
  div,
  a {
    padding: 0px 0px 20px;
    font-size: 1em;
  }
}
.showing {
  max-height: 220px;
}
@include media-breakpoint-up(md) {
  .collapse {
    max-height: 140px;
    padding: 0px;
    div,
    a {
      padding: 3px 0px;
      font-size: 0.9em;
    }
  }
}

.text-small {
  font-size: 0.8em;
}
</style>
