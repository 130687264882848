<template>
  <router-link
    :to="{
      name: 'showProduct',
      params: { product: product.slug, productName: product.name },
    }"
    custom
    v-slot="{ navigate }"
  >
    <div class="search-result-outer" ref="container" @click="navigate" :class="{selected: selected}">
      <div class="product-list-item" :class="{ odd: isOdd }">
        <div class="row h-100 align-items-between justify-content-center">
          <div class="col-auto">
            <img
              :src="image()"
              width="100px"
              height="200px"
              :alt="product.name + ' ' + product.sub_title"
              class="img-fluid rounded"
            />
          </div>
          <div class="col d-flex flex-column justify-content-around">
            <h1 class="mb-1 mt-2 product-name" style="font-weight: bold">
              {{ product.name }}
            </h1>

            <small class="subtitle">{{ product.sub_title }}</small>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>
<script>
import imageProxy from "../../imageProxy";

export default {
  components: {},
  props: ["product", "index","selected"],
  data() {
    return {};
  },
  computed: {
    isOdd() {
      return this.index % 2;
    },
    reusability() {
      var aspect = this.getAspectByName("Reusability");
      if (aspect !== undefined) {
        return aspect.options[0].name;
      }
      return null;
    },
  },
  methods: {
    getAspectByName(name) {
      var found;
      this.product.aspects.forEach((aspect) => {
        if (aspect.name == name) {
          found = aspect;
        }
      });
      return found;
    },
    image() {
      var image = this.product.images[0];
      if (image !== undefined) {
        var source = this.product.images[0].origin_src;
        return imageProxy.image(source, 200, 200);
      }

      return "";
    },
  },

  filters: {
    asMoney(value) {
      return (Math.round((value + Number.EPSILON) * 100) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.2em;
  cursor: pointer;
}

h2 {
  font-size: 1.2em;
  small {
    font-size: 0.5em;
    display: block;
    font-weight: normal;
    opacity: 0.8;
  }
}

.search-result-outer {
  height: 100%;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: rgb(244, 244, 244);
  }
  &.selected {
    background-color: rgb(244, 244, 244);
  }
}

.product-list-item {

  color: black;
  position: relative;
  padding: 10px;
  // padding-top: 50px;
  // border: 1px solid rgb(240, 240, 240);
  height: 100%;
  border-bottom:1px solid rgb(240, 240, 240);
  .product-image {
    margin-bottom: 10px;
    border-radius: 10px;
  }
}



.view-product {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #feb103;
  border: none;
  color: black;
  padding: 10px 20px;
  border-radius: 3px;
  text-decoration: none;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  &:hover {
    background: rgb(43, 43, 43);
  }
}

.subtitle {
  margin-top: -2px;
  opacity: 0.8;
  font-size: 0.8em;
}
</style>